.facturacion-agua-form .toast {
    max-width: 50%; /* Ajusta el tamaño según tus necesidades */
    left: 50%;
    transform: translateX(-50%);
  }
  .facturacion-agua-form .tostada{
    margin-left: -125px; /* Divide value of min-width by 2 */
    text-align: center; /* Centered text */
    border-radius: 60px; /* Rounded borders */
     /* Padding padding: 16px;*/
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    background-color: green;
    /*font-size: 40px !important;*/
  }
  .facturacion-agua-form .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); /* Fondo semitransparente */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* Asegúrate de que el modal esté encima de otros elementos */
}

.facturacion-agua-form .modal img {
    max-width: 60%; /* Ancho máximo del 60% */
    max-height: 80%; /* Alto máximo del 80% */
    border-radius: 8px; /* Opcional: para un estilo más agradable */
}
.facturacion-agua-form .input-field {
  position: relative; /* Asegura que los elementos se posicionen correctamente */
  margin-bottom: 20px; /* Espaciado entre los campos */
}

.facturacion-agua-form .input-field select {
  z-index: 1; /* Asegura que el select tenga mayor prioridad */
}