.medicamentos-page-container {
    padding: 20px;
}

.medicamentos-page-container .medicamentos-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.medicamentos-page-container .medicamento-card {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

.medicamentos-page-container .medicamento-card h5 {
    margin-bottom: 10px;
    color: #007bff;
}

.medicamentos-page-container .medicamento-card p {
    margin: 5px 0;
}

.medicamentos-page-container .medicamento-archivos {
    margin-top: 10px;
}

.medicamentos-page-container .archivo-link {
    display: block;
    margin: 5px 0;
    color: #007bff;
    text-decoration: none;
}

.medicamentos-page-container .archivo-link:hover {
    text-decoration: underline;
}

.medicamentos-page-container .return-btn {
    margin-bottom: 20px;
}
