.publicacion-detalle-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .publicacion-detalle-container .publicacion-detalle-header h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .publicacion-detalle-container .mensajes-section {
    margin-top: 20px;
  }
  
  .publicacion-detalle-container .mensajes-list {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  
  .publicacion-detalle-container .mensaje {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
  }
  
  .publicacion-detalle-container .mensaje.enviado {
    background-color: #d1ffd1;
    text-align: right;
  }
  
  .publicacion-detalle-container .mensaje.recibido {
    background-color: #f1f1f1;
    text-align: left;
  }
  
  .publicacion-detalle-container .mensaje-input {
    display: flex;
    gap: 10px;
  }
  
  .publicacion-detalle-container .mensaje-input textarea {
    flex: 1;
    resize: none;
  }
  
  .publicacion-detalle-container .mensaje-input button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  