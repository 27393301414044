/* General container 

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  */
  /* Card styles 
  .card {
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #424242;
    margin-bottom: 10px;
  }
  */
  /* Buttons */
  .admin-encuesta .btn {
    margin-right: 10px;
  }
  
  .admin-encuesta .btn.orange {
    background-color: #fb8c00 !important;
  }
  
  .admin-encuesta .btn.blue {
    background-color: #1565c0 !important;
  }
  
  .admin-encuesta .btn.green {
    background-color: #2e7d32 !important;
  }
  
  .admin-encuesta .btn.red {
    background-color: #c62828 !important;
  }
  
  /* Input fields */
  .admin-encuesta .input-field {
    margin-bottom: 20px;
  }
  
  .admin-encuesta textarea.materialize-textarea {
    min-height: 80px;
  }
  
  .admin-encuesta label.active {
    color: #1565c0 !important;
    font-weight: bold;
  }
  
  
  
  /* Question list */
  .admin-encuesta .row.card {
    margin-bottom: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .admin-encuesta .row.card .card-content {
    padding: 15px;
  }
  
  .admin-encuesta .row.card .card-action {
    padding: 10px;
    text-align: right;
  }
  
  /* Options list */
  .admin-encuesta ul {
    list-style: none;
    padding: 0;
  }
  
  .admin-encuesta ul li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .admin-encuesta ul li input[type="text"] {
    margin-right: 10px;
  }
  
  /* Image preview */
  .admin-encuesta img {
    display: block;
    margin: 10px auto;
    max-width: 100%;
    max-height: 200px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  /* File input */
  .admin-encuesta .file-field .btn {
    margin: 0;
  }
  
  .admin-encuesta .file-field .file-path-wrapper {
    margin-top: 10px;
  }
  
  .admin-encuesta .file-path {
    font-size: 0.9rem;
    color: #616161;
  }
  
  /* Labels for required fields */
  .admin-encuesta span.required {
    color: red;
    font-weight: bold;
    margin-left: 5px;
  }
  
  /* Preview mode */
  .admin-encuesta h5 {
    margin-top: 20px;
    font-size: 1.3rem;
    font-weight: bold;
    color: #424242;
  }
  
  .admin-encuesta h6 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #424242;
  }
  
  /* Loading state */
  .admin-encuesta .progress {
    margin-top: 20px;
  }
  
  .admin-encuesta .indeterminate {
    background-color: #1565c0 !important;
  }
  