.admin-medicamentos-container {
    padding: 20px;
}

.admin-medicamentos-container h3 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
}

.admin-medicamentos-container .btn.blue {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
    transition: background 0.3s ease;
}

.admin-medicamentos-container .btn.blue:hover {
    background-color: #2980b9;
}

.admin-medicamentos-container .medicamentos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.admin-medicamentos-container .medicamento-item {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.2s ease;
}

.admin-medicamentos-container .medicamento-item:hover {
    transform: translateY(-5px);
}

.admin-medicamentos-container .medicamento-item h5 {
    font-size: 1.2rem;
    color: #34495e;
    margin-bottom: 10px;
}

.admin-medicamentos-container .medicamento-item p {
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 15px;
}

.admin-medicamentos-container .btn.yellow {
    background-color: #f1c40f;
    color: white;
    padding: 8px 15px;
    border-radius: 5px;
    transition: background 0.3s ease;
    margin-top: 10px;
}

.admin-medicamentos-container .btn.yellow:hover {
    background-color: #d4ac0d;
}

.admin-medicamentos-container .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    padding: 20px;
    z-index: 1001;
    max-height: 90%;
    overflow-y: auto;
}

.admin-medicamentos-container .modal h4 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.admin-medicamentos-container .modal input[type="text"],
.admin-medicamentos-container .modal input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.admin-medicamentos-container .modal-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}

.admin-medicamentos-container .modal .btn.green {
    background-color: #2ecc71;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.admin-medicamentos-container .modal .btn.green:hover {
    background-color: #27ae60;
}

.admin-medicamentos-container .modal .btn.red {
    background-color: #e74c3c;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.admin-medicamentos-container .modal .btn.red:hover {
    background-color: #c0392b;
}

.admin-medicamentos-container .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

@media screen and (max-width: 600px) {
    .admin-medicamentos-container .medicamento-item h5 {
        font-size: 1rem;
    }

    .admin-medicamentos-container .medicamento-item p {
        font-size: 0.9rem;
    }

    .admin-medicamentos-container .modal input[type="text"],
    .admin-medicamentos-container .modal input[type="number"] {
        font-size: 0.9rem;
    }

    .admin-medicamentos-container .btn.blue, .btn.yellow, .modal .btn.green, .admin-medicamentos-container .modal .btn.red {
        padding: 8px 10px;
        font-size: 0.9rem;
    }
}
