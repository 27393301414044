.admin-protocolos-container {
    padding: 20px;
}

.admin-protocolos-container .protocolos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.admin-protocolos-container .protocolo-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    transition: transform 0.2s ease;
}

.admin-protocolos-container .protocolo-card:hover {
    transform: translateY(-5px);
}

.admin-protocolos-container .custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    overflow-y: auto;
    max-height: 90%;
}

.admin-protocolos-container .modal-content {
    padding: 20px;
}

.admin-protocolos-container .modal-content h4 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.admin-protocolos-container .input-field {
    margin-bottom: 20px;
}

.admin-protocolos-container .input-field input,
.admin-protocolos-container .input-field textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.admin-protocolos-container .input-field textarea {
    resize: none;
}

.admin-protocolos-container .file-field {
    margin-top: 20px;
}

.admin-protocolos-container .file-field .btn {
    margin-right: 10px;
}

.admin-protocolos-container .file-path-wrapper {
    margin-top: 5px;
}

.admin-protocolos-container .file-list {
    margin-top: 15px;
}

.admin-protocolos-container .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.admin-protocolos-container .file-link {
    text-decoration: none;
    color: #007bff;
    word-wrap: break-word;
    max-width: 70%;
}

.admin-protocolos-container .remove-btn {
    margin-left: 10px;
}

.admin-protocolos-container .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.admin-protocolos-container .modal-footer .btn {
    margin-left: 10px;
}

.admin-protocolos-container .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

@media screen and (max-width: 600px) {
    .admin-protocolos-container .custom-modal {
        width: 95%;
        max-width: 95%;
        max-height: 95%;
    }

    .admin-protocolos-container .file-link {
        max-width: 60%;
    }
}
