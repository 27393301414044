.preferences-container {
    padding: 20px;
}

.preferences-container h3, h5 {
    color: #2c3e50;
    margin-bottom: 20px;
}

.preferences-container .section {
    margin-bottom: 30px;
}

.preferences-container .row {
    margin-bottom: 10px;
}

.preferences-container input[type="checkbox"] + span {
    font-size: 16px;
    color: #34495e;
}

.preferences-container input[type="number"] {
    margin-top: 10px;
}

.preferences-container label.active {
    color: #2c3e50;
}

.preferences-container button.btn.green {
    width: 100%;
    background-color: #27ae60;
}
