.carga-personas {
    margin-top: 50px;
  }
  
  .carga-personas .file-field .btn {
    line-height: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carga-personas .file-path-wrapper input {
    height: 45px;
  }
  