.plan-mantenimiento-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .plan-mantenimiento-tabla-container {
    overflow-x: auto;
  }
  
  .plan-mantenimiento-tabla {
    width: 100%;
    border-collapse: collapse;
  }
  
  .plan-mantenimiento-tabla th,
  .plan-mantenimiento-tabla td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 8px;
  }
  
  .plan-mantenimiento-tabla th {
    background-color: #2e7d32; /* Verde atractivo */
    color: white;
    font-weight: bold;
  }
  
  .plan-mantenimiento-tabla .grupo-activo .nombre-activo {
    background-color: #43a047; /* Verde atractivo */
    color: white;
    font-weight: bold;
    text-align: left;
    padding: 12px;
  }
  
  .plan-mantenimiento-tabla .actividad {
    text-align: left;
    padding-left: 10px;
  }
  