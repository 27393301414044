.mis-publicaciones-container {
    padding: 20px;
}

.mis-publicaciones-container .header {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el texto "Mis Publicaciones" */
    margin-bottom: 20px;
}

.mis-publicaciones-container .page-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
}

.mis-publicaciones-container .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-start; /* Alinea el botón a la izquierda */
    margin-top: 10px;
}

.mis-publicaciones-container .create-button {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 5px 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.3s;
}

.mis-publicaciones-container .create-button i {
    font-size: 16px;
}

.mis-publicaciones-container .create-button:hover {
    background-color: #45a049;
}

.mis-publicaciones-container .publicaciones-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.mis-publicaciones-container .publicacion-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
}

.mis-publicaciones-container .card-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 200px;
}

.mis-publicaciones-container .card-content {
    padding: 15px;
}

.mis-publicaciones-container .card-content h5 {
    font-size: 18px;
    margin: 0 0 10px 0;
}

.mis-publicaciones-container .card-content p {
    margin: 5px 0;
    color: #555;
}

.mis-publicaciones-container .card-action {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f8f8f8;
    border-top: 1px solid #ddd;
}

.mis-publicaciones-container .card-action .btn {
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;
}
