.contenido-page {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.react-quill-container .ql-toolbar {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-quill-container .ql-container {
  height: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contenido-page .button-group {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.contenido-page .button-group .btn {
  margin-right: 10px;
}
