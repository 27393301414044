.container {
    margin-top: 20px;
}

.persona-asistencia-form form {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
}

.persona-asistencia-form h4, h5 {
    margin-bottom: 20px;
    color: #2c3e50;
}

.persona-asistencia-form .input-field {
    margin-bottom: 20px;
}

.persona-asistencia-form .input-field label {
    color: #34495e !important;
}

.persona-asistencia-form .input-field input:focus + label,


.persona-asistencia-form .input-field input:focus,

.persona-asistencia-form .contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.persona-asistencia-form .contact-item .input-field {
    margin: 0;
    flex: 1;
}

.persona-asistencia-form .contact-item button {
    height: 36px;
    padding: 0 10px;
    font-size: 14px;
}

.persona-asistencia-form .contact-item .btn.red {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .persona-asistencia-form .contact-item {
        flex-wrap: wrap;
    }

    .persona-asistencia-form .contact-item button {
        margin-top: 10px;
        width: 100%;
    }
/*
    .btn {
        width: 100%;
        margin-top: 10px;
    }
        */
}
.persona-asistencia-form .button-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.persona-asistencia-form .button-group .btn {
    margin: 0;
    height: 36px; /* Para mantener uniformidad */
}