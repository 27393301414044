.mis-zonas-seguras-container {
    padding: 20px;
}

.mis-zonas-seguras-container .zonas-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.mis-zonas-seguras-container .zona-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease;
}

.mis-zonas-seguras-container .zona-card:hover {
    transform: translateY(-5px);
}

.mis-zonas-seguras-container .zona-card-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    justify-content: center;
}

.mis-zonas-seguras-container .zona-image {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
}

.mis-zonas-seguras-container .zona-card-content {
    padding: 15px;
}

.mis-zonas-seguras-container .zona-card-content h5 {
    margin: 10px 0;
    font-size: 1.5rem;
    color: #333;
}

.mis-zonas-seguras-container .zona-card-content p {
    margin: 5px 0;
    font-size: 1rem;
    color: #666;
}
