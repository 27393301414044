.requerimiento-form .toast {
  max-width: 50%; /* Ajusta el tamaño según tus necesidades */
  left: 50%;
  transform: translateX(-50%);
}
.requerimiento-form .tostada{
  margin-left: -125px; /* Divide value of min-width by 2 */
  text-align: center; /* Centered text */
  border-radius: 60px; /* Rounded borders */
   /* Padding padding: 16px;*/
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  background-color: green;
  /*font-size: 40px !important;*/
}

/* Historial de Mensajes */
.requerimiento-form .message-header {
font-size: 1.2em;
margin-bottom: 10px;
color: #333;
text-align: center;
}

.requerimiento-form .message-history {
max-height: 300px;
overflow-y: auto;
padding: 15px;
background-color: #f9f9f9;
border-radius: 10px;
margin-bottom: 20px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.requerimiento-form .message-item {
display: flex;
margin-bottom: 10px;
}

.requerimiento-form .message-item.sent {
justify-content: flex-end;
}

.requerimiento-form .message-item.received {
justify-content: flex-start;
}

.requerimiento-form .message-bubble {
max-width: 70%;
padding: 10px 15px;
border-radius: 15px;
position: relative;
font-size: 0.9em;
line-height: 1.4;
background-color: #e0f7fa;
color: #006064;
}

.requerimiento-form .message-item.sent .message-bubble {
background-color: #d1c4e9;
color: #512da8;
border-radius: 15px 15px 0 15px;
}

.requerimiento-form .message-item.received .message-bubble {
background-color: #e3f2fd;
color: #1565c0;
border-radius: 15px 15px 15px 0;
}

.requerimiento-form .message-date {
font-size: 0.7em;
color: #999;
margin-top: 5px;
text-align: right;
}

.requerimiento-form .message-bubble a {
color: #0288d1;
text-decoration: underline;
display: inline-block;
margin-top: 5px;
}

/* Nueva sección de mensaje */
.requerimiento-form .new-message-section {
margin-top: 20px;
padding: 15px;
background-color: #f1f1f1;
border-radius: 10px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.requerimiento-form .new-message-section h5 {
font-size: 1.2em;
color: #333;
margin-bottom: 15px;
}

.requerimiento-form .file-field .btn {
background-color: #64b5f6;
}

.requerimiento-form .file-path-wrapper .file-path {
color: #333;
}

.requerimiento-form .message-bubble strong {
  display: block;
  font-size: 0.9em;
  color: #333;
  margin-bottom: 5px;
}
