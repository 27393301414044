.publicacion-page-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.publicacion-page-container .content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* Formulario */
.publicacion-page-container .form-section {
    flex: 1;
}

/* Vista previa */
.publicacion-page-container .preview-section {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
}

/* Estilo para la tarjeta de vista previa */
.publicacion-page-container .preview-card {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

/* Estilo para las imágenes de vista previa */
.publicacion-page-container .images-preview img {
    max-width: 100%;
    height: auto;
    margin: 5px 0;
}
.publicacion-page-container .page-header {
    display: flex;
    align-items: center;
    gap: 20px; /* Espaciado entre el botón y el título */
    margin-bottom: 20px;
}

.publicacion-page-container .back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #007bff;
    font-weight: 600;
    font-size: 1rem;
    text-transform: capitalize;
}

.publicacion-page-container .back-button i {
    font-size: 1.5rem;
}

.publicacion-page-container .page-header h3 {
    margin: 0;
    font-size: 1.8rem;
    text-align: center;
    flex: 1; /* Asegura que el título ocupe el espacio restante */
}
/* Ajustes específicos para dispositivos pequeños */
@media (max-width: 768px) {
    .publicacion-page-container .images-preview img {
        max-width: 100%;
        height: auto;
        margin: 5px 0;
    }
}



/* Grupos de checkbox */
.publicacion-page-container .checkbox-group {
    margin-bottom: 20px;
}

.publicacion-page-container .checkbox-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.publicacion-page-container .checkbox-item input {
    margin-right: 10px;
}

/* Botones de acción */
.publicacion-page-container .action-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
    .publicacion-page-container .content {
        flex-direction: column; /* Cambiar la disposición a columna */
    }

    .publicacion-page-container .form-section {
        order: 1; /* Asegurar que el formulario esté primero */
    }

    .publicacion-page-container .preview-section {
        order: 2; /* Asegurar que la vista previa esté debajo */
    }

    .publicacion-page-container .action-buttons {
        flex-direction: column; /* Botones en columna en pantallas pequeñas */
    }

    .publicacion-page-container .action-buttons .btn {
        width: 100%; /* Botones de ancho completo */
    }
}

/**/
/* Contenedor de mensajes */
.publicacion-page-container .chat-box {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.publicacion-page-container .message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    max-width: 80%;
    word-wrap: break-word;
}

.publicacion-page-container .message-in {
    background: #e0f7fa;
    text-align: left;
}

.publicacion-page-container .message-out {
    background: #c8e6c9;
    text-align: right;
    margin-left: auto;
}

.publicacion-page-container .message-content p {
    margin: 0;
    font-size: 14px;
}

.publicacion-page-container .message-time {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    display: block;
    text-align: right;
}

/* Sección de mensajes nuevos */
.publicacion-page-container .new-message-section {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 10px; /* Espacio entre los elementos */
}

/* Caja de texto */
.publicacion-page-container .message-input {
    flex: 1;
    resize: none;
    height: 2.5rem;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Botón de adjuntar archivo */
.publicacion-page-container .file-upload-container {
    position: relative;
    display: flex;
    align-items: center;
}

.publicacion-page-container .file-upload-icon {
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.publicacion-page-container .file-upload-icon:hover {
    background-color: #0056b3;
}

.publicacion-page-container .file-upload-icon i {
    font-size: 20px;
}

.publicacion-page-container .file-input {
    display: none; /* Ocultar input de archivo */
}

.publicacion-page-container .file-details {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.publicacion-page-container .file-details span {
    font-size: 14px;
    margin-right: 10px;
}

.publicacion-page-container .file-details button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: red;
}

/* Botón de enviar */
.publicacion-page-container .send-button {
    background: linear-gradient(90deg, #4caf50, #81c784);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: all 0.3s ease;
    cursor: pointer;
}

.publicacion-page-container .send-button i {
    font-size: 20px;
}

.publicacion-page-container .send-button:hover {
    background: linear-gradient(90deg, #81c784, #4caf50);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.publicacion-page-container .send-button.disabled {
    background: gray;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Icono giratorio */
.publicacion-page-container .send-button .spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
    .publicacion-page-container .new-message-section {
        flex-direction: column;
        align-items: stretch;
    }

    .publicacion-page-container .file-upload-icon,
    .publicacion-page-container .send-button {
        margin: 0 auto;
    }
}
