.protocolos-container {
    padding: 20px;
}

.protocolos-container h3 {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 10px;
    text-align: center;
}

.protocolos-container p {
    color: #7f8c8d;
    font-size: 1rem;
    margin-bottom: 20px;
    text-align: center;
}

.protocolos-container .protocolos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.protocolos-container .protocolo-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.2s ease;
}

.protocolos-container .protocolo-card:hover {
    transform: translateY(-5px);
}

.protocolos-container .protocolo-card h5 {
    font-size: 1.5rem;
    color: #34495e;
    margin-bottom: 10px;
}

.protocolos-container .protocolo-card p {
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 15px;
}

.protocolos-container .protocolo-archivos {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrar botones horizontalmente */
    gap: 10px;
}

.protocolos-container .archivo-btn {
    display: inline-block;
    background: linear-gradient(135deg, #3498db, #1abc9c);
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 8px 12px;
    border-radius: 5px;
    text-decoration: none;
    transition: background 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: auto; /* Ajusta el ancho al contenido */
    min-width: 100px; /* Evitar botones demasiado pequeños */
    text-align: center; /* Centrar texto dentro del botón */
}

.protocolos-container .archivo-btn:hover {
    background: linear-gradient(135deg, #1abc9c, #3498db);
    transform: translateY(-3px);
}

.protocolos-container .archivo-btn:active {
    transform: translateY(1px);
}

@media (max-width: 600px) {
    .protocolos-container .protocolo-card h5 {
        font-size: 1.2rem;
    }

    .protocolos-container .protocolo-card p {
        font-size: 0.9rem;
    }

    .protocolos-container .archivo-btn {
        font-size: 0.8rem;
        padding: 6px 10px;
        min-width: 80px; /* Más pequeño en pantallas móviles */
    }
}

