/* Estilo para el modal */
.custom-modal {
    width: 80%; /* Ajusta el ancho del modal */
    max-width: 600px; /* Ajusta el ancho máximo */
    margin: auto; /* Centra el modal horizontalmente */
    padding: 20px; /* Agrega padding interno */
    background: #fff; /* Color de fondo */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
    position: relative; /* Asegúrate de que el modal sea relativo */
    z-index: 1050; /* Ajusta el z-index para que esté por encima de otros elementos */
  }
  
  /* Estilo para el overlay del modal */
  .custom-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Color de fondo del overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Asegúrate de que el overlay sea fijo */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040; /* Ajusta el z-index para que esté por debajo del modal */
  }
  