#cont_login {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: flex-start; /* Alinea el contenido en la parte superior */
    min-height: 100vh; /* Ocupa toda la altura de la pantalla */
    background-color: #f5f5f5; /* Fondo claro */
    padding-top: 20px; /* Espacio en la parte superior */
  }
  
  .login .formulario {
    width: 100%;
    max-width: 400px; /* Ancho máximo del formulario */
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .login .input-field {
    margin-bottom: 20px;
  }
  
  .login button {
    width: 100%;
  }
  
  .login h5.center {
    margin-bottom: 20px;
    text-align: center;
    color: #333; /* Color del texto del título */
  }
  