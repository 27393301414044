.toast {
    max-width: 50%; /* Ajusta el tamaño según tus necesidades */
    left: 50%;
    transform: translateX(-50%);
  }
.tostada{
    margin-left: -125px; /* Divide value of min-width by 2 */
    text-align: center; /* Centered text */
    border-radius: 60px; /* Rounded borders */
     /* Padding padding: 16px;*/
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    background-color: green;
    /*font-size: 40px !important;*/
  }