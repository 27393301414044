.container {
    margin: 20px auto;
    max-width: 800px;
}

.encuesta-form .card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.encuesta-form .card-content {
    margin-bottom: 20px;
}

.encuesta-form .card-content h5 {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #2c3e50;
}

.encuesta-form .card-content p {
    font-size: 1.2em;
    color: #7f8c8d;
    margin-bottom: 20px;
}

.encuesta-form .pregunta-card {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #bdc3c7;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.encuesta-form .pregunta-texto {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.encuesta-form .respuesta-input {
    width: 100%;
    padding: 8px 12px;
    font-size: 1em;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    margin-top: 10px;
    color: #2c3e50;
}

.encuesta-form .respuesta-input:focus {
    border-color: #2980b9;
    outline: none;
    box-shadow: 0 0 5px rgba(41, 128, 185, 0.5);
}

.encuesta-form .opciones-lista {
    list-style: none;
    padding: 0;
    margin: 10px 0;
}

.encuesta-form .opcion-item {
    margin-bottom: 10px;
}

.encuesta-form .adjunto-container {
    margin-top: 10px;
    text-align: center;
}

.encuesta-form .adjunto-imagen {
    max-width: 100%;
    max-height: 200px;
    border-radius: 4px;
}

#btn-carga-personas {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: white;
    background-color: #2980b9;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#btn-carga-personas:hover {
    background-color: #3498db;
}

#btn-carga-personas:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
}
