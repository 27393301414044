/* Contenedor principal del dashboard */
.dashboard-container {
  margin-top: 1rem; /* Espacio superior reducido */
  padding-top: 0.5rem;
}

/* Título de la página Dashboard */
.dashboard-title {
  font-size: 1.8rem; /* Reducir tamaño de fuente */
  margin: 0.5rem 0; /* Reducir margen superior e inferior */
  font-weight: 600; /* Hacerlo un poco más negrita */
  color: #333; /* Color oscuro para destacar */
}

/* Ajustar espacio entre secciones */
.section {
  margin-bottom: 1rem;
}

/* Ajustar el diseño de las tarjetas */
.card {
  margin: 0.5rem 0;
  border-radius: 8px; /* Bordes más suaves */
}

/* Contenedor de íconos y títulos */
.icon-block {
  display: flex;
  flex-direction: column; /* Alinear elementos en columna */
  justify-content: center; /* Centrado vertical */
  align-items: center; /* Centrado horizontal */
  text-align: center; /* Alineación del texto */
  margin-bottom: 20px;
  cursor: pointer;
}

/* Icono */
.icon-block i {
  font-size: 48px; /* Tamaño del ícono */
  color: #ffffff; /* Color del ícono */
  margin-bottom: 10px; /* Espacio entre ícono y título */
}

/* Título debajo del ícono */
.icon-block p {
  font-size: 16px; /* Tamaño del texto */
  color: #ffffff !important; /* Blanco puro con alta prioridad */
  font-weight: 300; /* Texto más grueso */
  text-align: center; /* Alinear el texto al centro */
  margin: 0; /* Eliminar márgenes predeterminados */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Sombra para contraste */
}