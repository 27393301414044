.lista-personas-asistencia-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.lista-personas-asistencia-container h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.personas_asistencia-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.persona-item {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.persona-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.persona-item h5 {
    margin: 0;
    font-size: 20px;
    color: #00796b;
}

.persona-item p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
}

.persona-item .btn {
    margin-top: 10px;
    font-size: 14px;
}

.lista-personas-asistencia-container .btn.yellow {
    background-color: #fbc02d;
    color: #fff;
}

.lista-personas-asistencia-container .btn.yellow:hover {
    background-color: #f9a825;
}

.lista-personas-asistencia-container .btn.blue {
    background-color: #1976d2;
    color: #fff;
    margin-bottom: 15px;
}

.lista-personas-asistencia-container .btn.blue:hover {
    background-color: #1565c0;
}
