.contactos-referencia-container {
    margin: 20px;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
}

.contactos-referencia-container h3 {
    margin-bottom: 30px;
    color: #e3320e;
    text-align: center;
}

.contactos-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
}

.contacto-card {
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
}

.contacto-logo img {
    max-width: 100px;
    height: auto;
    margin-bottom: 10px;
}

.contacto-info {
    width: 100%;
}

.contacto-nombre {
    font-size: 1.5rem;
    font-weight: bold;
    color: #34495e;
    margin-bottom: 10px;
}

.contacto-telefono {
    font-size: 1.6rem;
    color: #d35400; /* Resaltado */
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Sombras para visibilidad */
    margin-bottom: 10px;
    text-align: center;
}

.contacto-info p {
    margin: 5px 0;
    color: #555;
}

.contacto-info p strong {
    color: #34495e;
}

@media (max-width: 1024px) {
    .contactos-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .contactos-list {
        grid-template-columns: 1fr;
    }

    .contacto-card {
        width: 100%;
    }
}
