.container {
    margin: 20px auto;
    max-width: 800px;
}

.respuesta-form .card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.respuesta-form .card-content h5 {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #2c3e50;
}

.respuesta-form .card-content p {
    font-size: 1.2em;
    color: #7f8c8d;
    margin-bottom: 20px;
}

.pregunta-card {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #bdc3c7;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.pregunta-texto {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.respuesta-texto {
    font-size: 1em;
    color: #2c3e50;
    margin-top: 10px;
}

.opciones-lista {
    list-style: none;
    padding: 0;
    margin: 10px 0;
}

.opcion-item {
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    background-color: #f4f4f4;
}

.opcion-seleccionada {
    font-weight: bold;
    color: #ffffff;
    background-color: #2980b9;
}

.adjunto-container {
    margin-top: 10px;
    text-align: center;
}

.adjunto-imagen {
    max-width: 100%;
    max-height: 200px;
    border-radius: 4px;
}

.opcion-seleccionada {
    font-weight: bold;
    color: white;
    background-color: #2980b9;
    border-radius: 5px;
    padding: 5px;
}
