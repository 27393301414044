.registro-asistencia-container {
    padding: 20px;
}

.registro-asistencia-container .asistencia-form {
    margin-bottom: 20px;
}

.registro-asistencia-container .personas-list {
    margin-top: 20px;
}

.registro-asistencia-container .persona-item {
    background: #f4f4f4;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.registro-asistencia-container .persona-item h5 {
    color: #007bff;
}

.registro-asistencia-container .persona-item p {
    margin: 5px 0;
}
