.mis-reuniones .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.mis-reuniones .custom-modal {
    background: #fff;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.mis-reuniones .modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.mis-reuniones .token-inputs input {
    text-align: center;
    font-size: 1.5rem;
}
